/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

 @import 'swiper/scss';

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "fugazOne";
  src: url("./assets/fonts/FugazOne-Regular.ttf");
}

@font-face {
  font-family: "avenir-medium";
  src: url("./assets/fonts/Avenir Medium.ttf");
}

@font-face {
  font-family: "avenir-black";
  src: url("./assets/fonts/Avenir Black.ttf");
}

@font-face {
  font-family: "avenir-book";
  src: url("./assets/fonts/Avenir Book.ttf");
}

@font-face {
  font-family: "avenir-heavy";
  src: url("./assets/fonts/Avenir Heavy.ttf");
}

@font-face {
  font-family: "avenir-light";
  src: url("./assets/fonts/Avenir Light.ttf");
}

@font-face {
  font-family: "avenir-regular";
  src: url("./assets/fonts/Avenir Regular.ttf");
}

@font-face {
  font-family: "avenir-roman";
  src: url("./assets/fonts/avenir-roman.ttf");
}

@font-face {
  font-family: "myriadpro-bold";
  src: url("./assets/fonts/MyriadPro-Bold.otf");
}

@font-face {
  font-family: "myriadpro-regular";
  src: url("./assets/fonts/Myriad Pro Regular.ttf");
}

:root {
  --black: #414141;
  --ion-font-family: avenir-regular !important;
  font-family: avenir-regular !important;
}

:root[mode="ios"] .avenir-regular,
:root[mode="md"] .avenir-regular {
  --ion-font-family: avenir-regular !important;
  font-family: avenir-regular !important;
}

ion-input,
ion-select {
  --padding-start: 15px !important;

}



ion-content {
  font-family: sans-serif !important;
  --background: var(--ion-color-app-background) !important;
  background-color: var(--ion-color-app-background) !important;

  div {
    background-color: transparent;
    --background: transparent;
  }

  ion-item {
    background-color: transparent !important;
    --background: transparent !important;
  }

  ion-list {
    background-color: transparent !important;
    --background: transparent !important;
  }
}

ion-footer {
  --background: var(--ion-color-app-background);
  background-color: var(--ion-color-app-background);
}

ion-back-button {
  --background: #ffffff;
  --color: #000000;
  --border-radius: 50%;
  --margin-start: 8px;
  height: 40px;
  width: 40px;
}

ion-loading.custom-loading {
  --background: #e3edff;
  --spinner-color: var(--ion-color-secondary);

  color: var(--ion-color-secondary);
}

.input {
  border: 2px solid var(--ion-color-dark);
  border-radius: 8px;
}

.input-error {
  border: 2px solid var(--ion-color-danger) !important;
}

/* hide arrow form input type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ion-mt-0 {
  margin-top: 0 !important;
}


.fc-col-header-cell-cushion {
  color: #F0592C !important;
}

.fc .fc-button-primary {
  background-color: #F0592C !important;
  border-color: #F0592C !important;
}

.fc-timegrid-slot-label-cushion {
  color: black !important;
}

.fc-v-event {
  border-color: #F0592C !important;
}

.fc-timegrid-event {
  border-color: #F0592C !important;
}

.fc-toolbar-title {
  color: black !important;
}

.fc-today-button {
  border-radius: 4px !important;
} 

.fc-button-group {
  gap: 4px !important;
}

.fc-next-button {
  border-radius: 4px !important;
}

.fc-prev-button {
  border-radius: 4px !important;
}

.tentativeEvent {
  background: repeating-linear-gradient(
      45deg,
      #F0592C,
      #f06034 10px,
      #f38868 10px,
      #f38868 20px
  ) !important;
}
/* Mechanic Order Card Style */
ion-card.mechanic-order-card {
  margin: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  ion-card-title {
    text-align: center;
    padding: 12px 8px;
    color: #ffffff;
    font-size: 22px;
    background-color: var(--ion-color-secondary);
    border-bottom: 2px solid var(--ion-color-dark);
    border-radius: 7px;
  }

  ion-card-content {
    padding: 8px 0 0 0;

    ion-item {
      --min-height: 32px;
      --padding-top: 2px !important;
      --padding-bottom: 2px !important;

      p {
        color: black;
      }

      a {
        text-decoration: underline;
        text-decoration-color: var(--ion-color-secondary); //var(--ion-color-primary, #3880ff);
        color: black;
      }
    }

    .small-black-text {
      font-size: 14px;
    }

    .black-medium-text {
      margin: 0;
      color: black;
      font-size: 14px;
      font-weight: 600;
    }

    .repair-name {
      margin: 0 0 0 8px;
      align-self: center;

      .orange-text {
        margin: 0;
        color: var(--ion-color-secondary);
        font-size: 16px;
        font-weight: bold;
      }

      .text-color {
        color: #000;
        font-weight: 300;
        font-size: 16px;
      }

      .orange-medium-text {
        margin: 0;
        color: var(--ion-color-secondary);
        font-size: 14px;
        font-weight: 600;
      }
    }

    .location {
      --background: var(--ion-color-dark);
      background: var(--ion-color-dark) !important;

      ion-label {
        display: flex;
        align-items: flex-start;
        margin: 4px 0;
        color: white;
        font-size: 13px;

        ion-icon {
          margin-right: 10px;
          margin-left: 10px;
          font-size: 25px;
          align-self: center;
        }

        ion-text {
          max-width: calc(100% - 95px);
          margin-left: 4px;
          white-space: normal;
        }

        p {
          color: white;
        }
      }
    }

    .more-details {
      --background: var(--ion-color-success);

      ion-label {
        color: white;
        font-weight: bold;
      }
    }
  }
}

.change-my-mind {
  --height: 410px !important;
  align-items: flex-end;
}

ion-tab-button {
  --color-focused: #414141;
  --color-selected: #414141;
}

.toast-middle {
  .toast-message {
    font-size: 16px;
  }
}

.toast-mechanic-not-found {
  --background: var(--ion-color-danger);
  --color: white;
  --border-color: var(--ion-color-danger); // rgb(128, 105, 0);
  --border-width: 0px;
  --border-style: solid;
  font-size: 26px !important;

  .toast-message {
    font-size: 26px;
  }
}

/** Status Popover **/
.status-popover {
  --width: 255px;

  ion-item {
    text-align: center;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    --padding-start: 10px;
    --inner-padding-end: 0px;
    --background: var(--ion-color-secondary);
    --inner-border-width: 0;

    ion-radio {
      --color-checked: white;
      margin: 0px 8px 0 0;
    }
  }
}

// ---------------------------------------
.login-unlock-modal-class {
  --background: #2f2f302e;
}

.modal-color {
  text-align: center;
  padding: 15px;
  background: white;
  margin-top: 150px;
  margin-left: 25px;
  margin-right: 31px;
  border-radius: 13px;
}

.native-input.sc-ion-input-ios {
  color: black;
  opacity: 1 !important;
}

.youtube-button {
  text-align: center;
  border: 1px solid #333;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  font-size: 15px !important;
  align-items: center;
  text-transform: uppercase;

  // padding-top: 10px !important;
  // padding-bottom: 10px !important;
  .img-icon {
    height: 25px;
    width: 24px;
    margin-right: 10px;
  }
}

.plus-minus-icon {
  height: 25px;
  width: 25px;
}

.orderStatusText {
  max-width: 100%;
  margin: 0;
  padding: 0 4px 0 0;
  text-transform: uppercase;
  color: #fff;
  width: 100%;
}

ion-searchbar {

  /* overall styles here */
  .searchbar-input-container {
    searchbar-input.sc-ion-searchbar-ios {
      font-size: 14px !important;
      font-weight: 100 !important;
      contain: strict;
      color: gray !important;
      letter-spacing: 0.5px;
    }
  }
}

.no-mechanics-css {
  z-index: 20005;
  height: 180px;
  display: flex;
  margin: auto 20px;
  text-align: center;
  border-radius: 8px;

  h6 {
    margin: auto;
    color: #000 !important;
  }

  ion-button {
    width: 150px;
    display: flex;
    margin: 10px auto;
    --background: #ff5d00;
  }
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style-iw {
  background: #ef6725 !important;
  // background: #8d8d8d !important;
  overflow: hidden !important;
  top: 9px !important;
  left: 51px !important;
  border-radius: 1px !important;
  color: #fff;
  padding: 5px !important;
}

img[src$="#custom_marker"] {
  border-radius: 50%;
  border: 2px solid black !important;
}

.gm-style-iw-tc {
  background: #ef6725 !important;
  // background: #8d8d8d !important;
  clip-path: polygon(58% 0%, 4% 100%, 100% 0%) !important;
  content: "";
  height: 17px !important;
  left: 24px !important;
  position: absolute;
  top: 7px !important;
  width: 25px;
}

.gm-style .gm-style-iw-tc::after {
  display: none !important;
}

.specialist-modal {
  --background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  position: absolute;
  display: flex;
  margin-top: auto;
  /* border-radius: 35px; */
  border-radius: 16px 16px 0 0;

  .modal-wrapper {
    border-radius: 16px 16px 0 0;
  }

  .modal-container {
    border-radius: 16px 16px 0 0;
  }

  .modal {
    background: none;
  }

  .ion-checkbox {
    --background: none;
    --border-color: var(--ion-color-primary);
    --border-color-activated: var(--ion-color-primary);
  }

  .ion-label {
    color: var(--ion-color-primary);
  }

  ion-button {
    --border-radius: 0 0 16px 16px;
    --background: var(--ion-color-light);
    --color: var(--ion-color-primary);
    margin: 0px 8px 8px 8px;
    font-size: 20px;
    font-weight: 800;

    button {
      border-radius: 16px;
    }
  }

}

.order-completed-modal {
  --background: #ff5d00;
  

  ion-button {
    --background: #414141;
    width: 125px;
  }
}






/* Styles for screens with a minimum width of 1025 pixels (desktops) */
@media only screen and (min-width: 600px) {
  .youtube-btn {
    background: red !important;
    color: #fff;
    padding: 7px;
    border-radius: 5px;
    margin: auto 22px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
  }

  .order-completed-modal {
    --background: #ff5d00;
    

    ion-button {
      --background: #414141;
      width: 125px;
    }
  }

  ion-footer {
    //height: 20vh;
    width: 100vw;
    max-width: 500px;
    /* Your styles for desktops here */
  }

  img[src="/assets/icon/SVG/ic_disable_footer.svg"] {
    height: 65%;
    width: 100%;
    /* Your styles for the image on desktops here */
  }
}

ion-button {
  .next-contant {
    margin-left: 30px;
  }
}

calendar {
  .calendar-event-inner {
      background-color: #ef6725 !important;
  }
}